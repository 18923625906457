import React from "react";
import { TextareaAutosize } from "@material-ui/core";

export default class MyForm extends React.Component {
    
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    const text = "Feel free to message me.".split('');
    return (<>{status === "SUCCESS" ? <h1 className="  maintext1 mt-2  ">Köszönjük, hamarosan megkeressük.</h1> :
    <>
       
        <div className="flex  inset-auto object-center items-center self-center    pt-10 justify-center">
      <form className="flex flex-col min-w-full "
        onSubmit={this.submitForm}
        action="https://formspree.io/mdoonzoy"
        method="POST"
      >
      
      <div className="flex  justify-center">
            <label className="min-w-full text-center">
            <p className="maintext1 mt-2 "> Az Ön e-mail címe:</p>
            <input className="" type="text" name="_replyto"></input>
            </label>
        </div>
  <div className="flex text-center justify-center">
  <label className=" min-w-full">
  <p className="maintext1 mt-10">
   Az Ön üzenete:
    </p>
    <TextareaAutosize className="textresize" name="message "></TextareaAutosize>
  </label>
  </div>


  <div className="flex justify-center pt-6 ">
  <button className=" block"  type="submit">Küldés</button>  
  {status === "ERROR" && <p className="maintext1 mt-2">Kérem érvényes e-mail címet adjon meg</p>}</div>
      </form></div></>}
    </>);
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}