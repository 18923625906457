import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MyForm from "../components/form";
const tech = () => (
  <Layout>
    <SEO title="Kapcsolat" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl   m-auto">
    <div className="  flex  customflex flex-wrap    mt-20">

    <div id="elerhetoseg" className=" w-full flex  customflex flex-wrap  mt-10  normalbox">
<div className="F12F7B  w-full md:w-1/2  custom-h  p-4  text-left flex-col flex jus justify-around ">

<div><h3 className="maintext2 mt-2 "> 1148 Bp. Vezér utca 144. 
<h3 className="maintext2 mt-2 "> +36 20 500 7757 </h3> 
  </h3> </div>
<div>
<p className="maintext2 mb-0  text-left"> Hétfő - Szerda: 10 - 18</p> 
<p className="maintext2 mb-0 text-left"> Csütörtök: 10 - 19 </p> 
<p className="maintext2  mb-0 text-left"> Péntek: 10 - 17 </p> 


</div>
</div>
<div className=" w-full md:w-1/2  custom-h mt-10 md:mt-0 ">

<iframe width="100%" height="100%" frameborder="0" 
src="https://www.google.com/maps/embed/v1/place?q=vezeroptika&key=AIzaSyBYPtvX__2g6GBubDskuEgkKmoOU3oewX8" allowfullscreen></iframe>

</div>
</div>






    </div>
   

<div className="  flex     justify-center  normalbox mt-10 ">
<div  className="F12F7B  w-full   custom-h  p-4  text-left flex-col flex jus justify-around   ">

<div><h3 className="maintext1 mt-2 "> Fizetési Lehetőségek 

</h3>
<h3 className="maintext2 mt-2 "> Bankkártya </h3> 
<h3 className="maintext2 mt-2 "> Ep kártya </h3> 
<h3 className="maintext2 mt-2 "> SZÉP kártya </h3> 

 </div><div>

</div>
<div>
<p className="maintext2 mb-0  text-left"> Az üzlet környékén a parkolás ingyenes.</p> 

</div>



</div>






</div>

<div id="vevoszolgalat" className="  flex     justify-center   mt-10 ">
<div className="F12F7B  w-full   p-4  text-left flex-col flex jus justify-around  mb-10  ">

<h3 class="maintext1 m-10">Üzletünket elérheti személyesen a boltban, telefonon, illetve az alábbi Űrlapon keresztül</h3>




<MyForm></MyForm>











</div>






</div>


    </div>
  </Layout>
)

export default tech
